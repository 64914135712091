@import url(https://fonts.googleapis.com/css2?family=Questrial&display=swap);

body {
  color: #fff;
  background-color: #000;
  font-family: "Questrial", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.navlogo {
  display: block;
  height: 20px;
}

.cus-nav {
  background-color: transparent;
  color: #fff;
  padding: 0.4rem 0;
}

.link-style {
  color: #fff !important;
  margin-left: 1rem;
  font-weight: bold;
  text-align: center;
}

.nav-logo {
  height: 60px;
}

.linkh {
  color: #666;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
}
.linkh:after {
  display: block;
  content: "";
  border-bottom: solid 3px #634bcb;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.linkh:hover:after {
  transform: scaleX(1);
}
.linkh.fromRight:after {
  transform-origin: 100% 50%;
}
.linkh.fromLeft:after {
  transform-origin: 0% 50%;
}

.con-1 {
  margin-top: 5rem;
}
.exp-title {
  font-size: 1.3rem;
  font-weight: 900;
  color: #634bcb;
}
.exp-subtitle {
  color: #fff;
  line-height: 22px;
}

.bb {
  border-bottom: 1px solid#634bcb !important;
}

.faq {
  padding: 10rem 0;
}

.faqedit {
  border-radius: 1rem;
  background-color: #fff;
  padding: 0.1rem 0;
}

.con-bot {
  font-weight: 500;
  color: #000;
  font-size: 1.3rem !important;
  margin: 1rem 0;
}

.logo {
  height: 60px;
}

.bt {
  padding: 1rem;
}

.subtitle {
  font-size: 1.4rem;
  letter-spacing: 2px;
  font-weight: bold;
}

.showcase {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mih1 {
  font-weight: bold;
  font-size: 2.7rem;
  letter-spacing: 10px;
}

.cusbtn {
  background-color: #634bcb;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  border: 3px solid #000;
  font-weight: bold;
  border-radius: 20px;
  letter-spacing: 3px;
}

.plus-img {
  height: 60px;
  cursor: pointer;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}

.display-number {
  color: #000;
  font-weight: bold;
  height: 70px;
  width: 70px;
}
.number {
  font-weight: bold;
  font-size: 3rem;
}

.si {
  height: 40px;
  margin: 0 10px;
}

.text-wrapper {
  margin-top: 2rem;
}
.number-text {
  font-size: 2rem;
  font-weight: bold;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
}

.plus-img {
  height: 60px;
}

.about-content {
  padding: 10rem 0;
}

.collective-content {
  padding: 10rem 0;
}

.num-content {
  padding: 10rem 0;
}

.mmt {
  margin-top: 8rem;
}

.footer {
  padding: 2rem 0;
}

.flogo {
  height: 15px;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #634bcb;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
}

/* Container around content */
.containerr {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.containerr::after {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  right: -10px;
  background-color: white;
  border: 4px solid #000;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
/* .left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent green;
} */

/* Add arrows to the right container (pointing left) */
/* .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
} */

/* Fix the circle for containers on the right side */
.right::after {
  left: -10px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: #000;
  color: #fff;
  position: relative;
  border-radius: 6px;
}

.rbox {
  background: #000;
  color: #fff;
}
.rbox-outline {
  border: 1px solid #000;
  color: #000;
  background: #fff;
  z-index: 55555 !important;
}
.titlediv {
  text-align: center;
}
.rtitle {
  padding: 0.7rem;
  border-radius: 10px;
}
.logotext {
  display: none;
}

@media only screen and (max-width: 600px) {
  .logo {
    height: 50px !important;
  }

  .logotext {
    display: block;
    font-size: 1rem;
  }

  .navlogo {
    display: none;
  }

  .flogo {
    height: auto;
  }

  .rtc {
    text-align: center !important;
  }

  .mih1 {
    font-size: 1.5rem;
  }

  .rmt-1 {
    margin-top: 1rem !important;
  }
  .rmt-2 {
    margin-top: 2rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }

  .widset {
    width: 100% !important;
  }

  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .containerr {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .containerr::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 20px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

.mint {
  border: 3px solid #fff;
  padding: 4rem;
  border-radius: 1.5rem;
  background-color: #a6dff788;
  z-index: 9999999999;
  margin-top: 3rem;
}

/* @media only screen and (max-width: 480px) {
  .navlogo {
    width: 100%;
    text-align: center;
    height: 10px;
  }
} */

